<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                v-model="queryParam.type"
                @change="$refs.table.refresh(true)"
                style="width: 100%"
              >
                <a-select-option :key="3">全部</a-select-option>
                <a-select-option :key="0">进口</a-select-option>
                <a-select-option :key="1">清关</a-select-option>
                <a-select-option :key="2">出口</a-select-option>
                <a-select-option :key="4">行政</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.serial_num" placeholder="根据订单编号搜索" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="进库核注编号">
              <a-input v-model="queryParam.check_num" placeholder="根据进库核注编号搜索" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="出库核注编号">
              <a-input v-model="queryParam.clear_num" placeholder="根据出库核注编号搜索" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="录单时间">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客服员">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客服员"
                style="width: 100%"
                v-model="queryParam.customer_id">
                <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                v-model="queryParam.fee_status_list"
                placeholder="请选择费用状态"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
                mode="multiple"
              >
                <a-select-option v-for="o in this.GLOBAL.feeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam.pay_target_id"
                placeholder="请选择付款对象"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in this.supplierOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-affix :offsetTop="50">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="auditOpen('basic_audit')">审核</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="auditOpen('undo_audit')">反审核</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleBillApply()">开票申请</a-button>
        <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleApplyBizAudit()">商务审核</a-button>
        <a-button type="primary" @click="handleExport('export_verify_charge_data')">导出应收对账单</a-button>
        <a-button type="primary" @click="handleExport('export_verify_pay_data')">导出应付对账单</a-button>
        <a-button type="danger" @click="handleExport('export_yikang_charge_data')">导出怡康对账单</a-button>
      </a-affix>
    </div>
    <s-table
      ref="table"
      size="default"
      :alert="{
        show: true,
        msg: showTotal,
        clear: true
      }"
      :rowKey="(record, index) => {
        return record.serial_num
      }"
      :expandIconColumnIndex="6"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :customRow="(record, index) => {
        return {
          style: {
            // background: record.charge_status === 1 ? '#fff7e6' : record.charge_status === 2 ? '#e6f7ff' : ''
          }
        };
      }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange, onSelect: onSelect, onSelectAll: this.selectAllItem }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="chargeFeeItem" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          <a-tag :color="chargeFeeStatusColor(item)" style="margin-bottom:2px;">
            {{ feeChargeStatusName(item) }}
          </a-tag>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <div slot="payFeeItem" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          <a-tag :color="payFeeStatusColor(item)" style="margin-bottom:2px;">
            {{ feePayStatusName(item) }}
          </a-tag>
          <span>{{ item.pay_target_name }} </span>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <template slot="action" slot-scope="text, record">
        <a @click="balanceDetail(record)">查看结算单</a>
        <a-divider type="vertical" />
        <a @click="paymentDetail(record)">查看付款单</a>
      </template>
    </s-table>
    <a-modal
      :visible="auditVisible"
      :confirmLoading="auditConfirmLoading"
      :title="auditType === 1 ? '审核' : '反审核'"
      :width="720"
      @cancel="auditVisible = false"
      @ok="submitAudit"
    >
      <a-checkbox
        :indeterminate="auditIndeterminate"
        :checked="auditCheckAll"
        @change="onCheckAllChangeAudit"
        style="padding-left:10px;padding-bottom:10px;"
      >
        全选
      </a-checkbox>
      <a-checkbox-group v-model="checkedAuditList" @change="onChangeAudit" style="width:100%;">
        <div style="margin-bottom: 20px;" v-for="item in auditData" :key="item.id">
          <p style="margin: 10px 0;font-size: 16px;font-weight:bold">订单编号：{{ item.serial_num }}</p>
          <div class="fee-area">
            <div class="fee-list">
              <p style="margin: 5px auto;">应收费用</p>
              <div v-for="c in item.charge" :key="c.id">
                <a-checkbox :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
            <div class="fee-list">
              <p style="margin: 5px auto;">应付费用</p>
              <div v-for="c in item.pay" :key="c.id">
                <a-checkbox :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>
    <a-modal
      :visible="balanceVisible"
      :confirmLoading="balanceConfirmLoading"
      title="创建结算单"
      :width="720"
      @cancel="balanceVisible = false"
      @ok="balanceSubmit"
    >
      <a-checkbox
        :indeterminate="indeterminate"
        :checked="checkAll"
        @change="onCheckAllChangeBalance"
        style="padding-left:10px;padding-bottom:10px;"
      >
        全选
      </a-checkbox>
      <a-tag color="#f50" style="margin-right:10px;">应收合计:{{ chargeMoney }}</a-tag>
      <a-tag color="#f50" style="margin-right:10px;">应付合计:{{ payMoney }}</a-tag>
      <a-checkbox-group v-model="checkedBalanceList" @change="onChangeBalance" style="width:100%;">
        <div style="margin-bottom: 20px;" v-for="item in balanceData" :key="item.id">
          <p style="margin: 10px 0;font-size: 16px;font-weight:bold">订单编号：{{ item.serial_num }}</p>
          <div class="fee-area">
            <div class="fee-list">
              <p style="margin: 5px auto;">应收费用</p>
              <div v-for="c in item.charge" :key="c.id">
                <a-checkbox :value="c.id"> {{ c.fee_name }}:{{ c.money }}{{ c.currency_name }} </a-checkbox>
              </div>
            </div>
            <div class="fee-list">
              <p style="margin: 5px auto;">应付费用</p>
              <div v-for="p in item.pay" :key="p.id">
                <a-checkbox :value="p.id"> {{ p.fee_name }}:{{ p.money }}{{ p.currency_name }} </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>
    <!--    创建付款单-->
    <a-modal
      :visible="paymentVisible"
      :confirmLoading="paymentConfirmLoading"
      title="创建付款单"
      :width="720"
      @cancel="paymentVisible = false"
      @ok="paymentSubmit"
    >
      <a-radio-group v-model="checkedPayTarget" @change="changePayTarget" button-style="solid">
        <a-radio-button v-for="item in paymentPayTarget" :value="item.id" :key="item.id">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
      <div style="padding: 20px">
        <a-checkbox
          :indeterminate="payIndeterminate"
          :checked="payCheckAll"
          @change="onCheckAllChangePayment"
          style="padding-left:10px;padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-tag color="#f50" style="margin-right:10px;">应付合计:{{ payMoney }}</a-tag>
        <a-checkbox-group v-model="checkedPaymentList" @change="onChangePayment" style="width:100%;">
          <div class="fee-area">
            <a-row :gutter="24" class="fee-list">
              <a-col :span="12" v-for="p in paymentData" :key="p.id">
                <a-checkbox :value="p.id">
                  {{ p.serial_num }}/{{ p.fee_name }}/{{ p.money }}{{ p.currency_name }}
                </a-checkbox>
              </a-col>
            </a-row>
          </div>
        </a-checkbox-group>
      </div>
    </a-modal>

    <a-modal
      :visible="paymentDetailVisible"
      rowKey="paymentId"
      title="付款单详情"
      :width="1200"
      @cancel="paymentDetailVisible = false"
    >
      <a-table :columns="paymentColumns" :dataSource="paymentDetailData" :pagination="false">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="action" slot-scope="text, record">
          <a @click="handlePayBills(record)">付款单详情</a>
        </template>
      </a-table>
      <span slot="footer">
        <a-button @click="paymentDetailVisible = false">关闭</a-button>
      </span>
    </a-modal>

    <a-modal
      :visible="balanceDetailVisible"
      rowKey="balanceId"
      title="结算单详情"
      :width="1200"
      @cancel="balanceDetailVisible = false"
    >
      <a-table :columns="balanceColumns" :dataSource="balanceDetailData" :pagination="false">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="action" slot-scope="text, record">
          <a @click="printDebitSheet(record)">结算单详情</a>
        </template>
      </a-table>
      <span slot="footer">
        <a-button @click="balanceDetailVisible = false">关闭</a-button>
      </span>
    </a-modal>

    <a-modal
      :visible="printCumsBillModel"
      :confirmLoading="printCumsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印客服结算单"
      @cancel="printCumsBillModel = false"
    >
      <div id="printCumsBill">
        <cums-bill
          :currentData="currentData"
          :balanceData="balanceBillData"
        ></cums-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printCumsBillBtn"
                  v-print="printCumsBill">确认打印</a-button>
        <a-button type="primary" @click="printCumsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <fms-pay-state-bill
          :currentData="currentData"
          :balanceData="balanceData"
        ></fms-pay-state-bill>
      </div>
      <template slot="footer">
        <a-button
          id="printStatementsPayBillBtn"
          key="submit"
          type="primary"
          v-print="printStatementsPayBill"
        >确认打印
        </a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button
          id="printPayBillBtn"
          key="submit"
          type="primary"
          v-print="printPayBill">确认打印
        </a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceConfirmLoading"
      title="开票申请"
      :width="980"
      @cancel="applyInvoiceCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="24">
          <div>
            <!-- <a-divider orientation="left">订单信息</a-divider> -->
            <a-col :span="8">
              <a-form-model-item label="委托单位">
                <a-input v-model="bizAuditForm.customer" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="所属部门">
                <a-input v-model="bizAuditForm.department" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="录入人">
                <a-input v-model="bizAuditForm.creator" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="申请时间">
                <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-divider orientation="left"
          >费用信息
            <a-tag color="#f50" style="margin-left:10px;">应收合计:{{ totalAmount }} 应付合计:{{ payTotalAmount }}</a-tag>
          </a-divider>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
            style="padding-left:10px;padding-bottom:10px;"
          >
            全选
          </a-checkbox>
          <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
            <a-col :span="24">
              <a-row v-for="item in bizAuditForm.fees" :key="item.id">
                <a-alert
                  :message="`业务编号:${item.orderNums}`"
                  type="info"
                  show-icon
                  style="margin-bottom:10px;"
                />
                <a-row>
                  <p class="sub-fee-title">应收费用</p>
                  <a-col
                    :span="12"
                    v-for="fee in item.feeList"
                    :key="fee.id"
                    style="padding-left:15px;padding-bottom:10px;"
                  >
                    <!-- <span>{{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</span> -->
                    <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>
                  </a-col>
                </a-row>
                <a-row>
                  <p class="sub-fee-title">应付费用</p>
                  <a-col
                    :span="12"
                    v-for="fee in item.payFeeList"
                    :key="fee.id"
                    style="padding-left:15px;padding-bottom:10px;"
                  >
                    <!-- <span>{{ fee.pay_target_name }} {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</span> -->
                    <a-checkbox :value="fee.id">{{ fee.pay_target_name }} {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</a-checkbox>
                  </a-col>
                </a-row>
              </a-row>
            </a-col>
          </a-checkbox-group>
          <div>
            <a-col :span="24">
              <a-form-model-item label="申请备注">
                <a-input v-model="applyInvoiceForm.apply_remark"></a-input>
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">开票信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位/客户名称" prop="customer_name">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo2"
                  :filter-option="false"
                  :not-found-content="fetching2 ? undefined : null"
                  @search="getDepartmentCustomer2"
                  @change="changeCustomerInfo2"
                >
                  <template v-if="fetching2" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerInfoOps2" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="纳税人识别号" prop="tax_no">
                <a-input v-model="applyInvoiceForm.tax_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="地址">
                <a-input v-model="applyInvoiceForm.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="电话">
                <a-input v-model="applyInvoiceForm.mobile" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-select v-model="applyInvoiceForm.bank_card_no" @change="bankAccountChange">
                  <a-select-option
                    :value="item.account"
                    v-for="item in bankAccountOps"
                    :key="item.account">
                    {{ item.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户银行">
                <a-input v-model="applyInvoiceForm.bank" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="发票类型" prop="invoice_type">
                <a-select v-model="applyInvoiceForm.invoice_type" @change="changeInvoiceType">
                  <a-select-option :value="item.value" v-for="item in invoiceTypeList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税率" prop="tax_rate">
                <a-select v-model="applyInvoiceForm.tax_rate">
                  <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="费用是否完整" prop="fee_complete">
                <a-select v-model="applyInvoiceForm.fee_complete" >
                  <a-select-option value="complete" key="complete">是</a-select-option>
                  <a-select-option value="incomplete" key="incomplete">否</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <edit-table
                :columns="invoiceSubjectColumns"
                :sourceData="invoiceSubjectData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getInvoiceSubjectTableDate"></edit-table>
            </a-col>
            <a-col :span="24">
              <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="开票备注">
                <a-input v-model="applyInvoiceForm.invoice_remark"></a-input>
              </a-form-model-item>
            </a-col>
          </div>
        </a-row>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="businessAuditFormVisible"
      :confirmLoading="businessAuditConfirmLoading"
      title="商务审核申请"
      :width="720"
      @cancel="billOrverifyCancel"
      @ok="billOrVerifySubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <div>
          <a-divider orientation="left">基础信息</a-divider>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="付款方式" prop="pay_method">
                <a-select v-model="form.pay_method">
                  <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="附件数" prop="appendix_num">
                <a-input suffix="张" v-model="form.appendix_num"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="发票号码" prop="invoice_number">
            <a-select
              showSearch
              mode="multiple"
              ref="invoice_number"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择发票号码"
              style="width: 100%"
              v-model="form.invoice_num"
            >
              <a-select-option v-for="op in invoiceRegOps" :key="op.id"
              >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </div>
        <a-form-model-item label="合同号" prop="contract_list">
          <a-select
            showSearch
            mode="multiple"
            ref="contract_list"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择合同号"
            style="width: 100%"
            v-model="form.contract_list"
          >
            <a-select-option v-for="op in leaseInfoList" :key="op.id">
              合同号:{{ op.contract_no }}
              <span v-if="op.is_no_term===0 && !op.contract_termination_date">/ 截至日期:{{ moment(op.contract_end_date).format('YYYY/MM/DD') }}</span>
              <span v-if="op.is_no_term===0 && op.contract_termination_date">/顺延日期:{{ moment(op.contract_termination_date).format('YYYY/MM/DD') }}</span>
              / {{ op.customer_info.customer_cn_name }}
              <span v-if="op.credit_period">/ 信用期：{{ op.credit_period }}天</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-divider orientation="left">费用信息</a-divider>
        <a-radio-group v-model="checkedPayTarget" @change="changePayTarget" button-style="solid">
          <a-radio-button v-for="item in paymentPayTarget" :value="item.id" :key="item.id">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
        <div style="padding: 20px">
          <a-checkbox
            :indeterminate="payIndeterminate"
            :checked="payCheckAll"
            @change="onCheckAllChangePayment"
            style="padding-left:10px;padding-bottom:10px;"
          >
            全选
          </a-checkbox>
          <a-tag color="#f50" style="margin-right:10px;">应付合计:{{ payMoney }}</a-tag>
          <a-checkbox-group v-model="checkedPaymentList" @change="onChangePayment" style="width:100%;">
            <div class="fee-area">
              <a-row :gutter="24" class="fee-list">
                <a-col :span="12" v-for="p in paymentData" :key="p.id">
                  <a-checkbox :value="p.id">
                    {{ p.serial_num }}/{{ p.fee_name }}/{{ p.money }}{{ p.currency_name }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </div>
          </a-checkbox-group>
        </div>
        <div>
          <a-form-model-item label="申请备注" prop="apply_remark">
            <a-textarea v-model="form.apply_remark"></a-textarea>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, SelectDrawer, EditTable } from '@/components'
import { getCustomer, getCustomerInfo, downloadExcel, getSupplier, getCommonNoPage, getLeaseInfoListByDepartment } from '@/api/common'
import { filterOption } from '@/utils/util'
import moment from 'moment'
import { getCumsFinancePage, CumsFinanceFunc, getCumsMetaOption, getCumsBalanceFunc, getCumsPayBillFunc, getBalanceDetailByOrderId, getPaymentDetailByOrderId } from '@/api/cums'
import { getBizAuditPage, getchargeTax, getFmsInfoDetail, listSecondInvoiceSubject } from '@/api/fms'
import debounce from 'lodash/debounce';
import cumsBill from '@/views/fms/bill/CumsBill';
import fmsPayStateBill from '@/views/fms/bill/FmsPayStatementBill';
import fmsPayBill from '@/views/fms/bill/FmsPayBill';
export default {
  components: {
    STable,
    SelectDrawer,
    cumsBill,
    fmsPayStateBill,
    fmsPayBill,
    EditTable
  },
  watch: {
    selectedRowKeys: function() {
      this.showTotal = `选择条数:${this.selectedRowKeys.length}`
      if (this.selectedRowKeys.length === 0) {
        this.selectedRows = []
      }
    }
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.lastFetchId2 = 0;
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800);
    return {
      showTotal: '选择条数:0',
      applyInvoiceVisible: false,
      applyInvoiceConfirmLoading: false,
      // 开票申请form
      applyInvoiceForm: {
        apply_remark: '',
        customer_name: '',
        customer_id: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: '',
        fee_complete: '',
        invoice_subject_data: [],
        invoice_remark: ''
      },
      applyInvoiceRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }],
        invoice_type: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        fee_complete: [{ required: true, message: '请确认费用是否完整', trigger: 'change' }]
      },
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printCumsBillModel: false,
      printCumsBillModelLoading: false,
      printCumsBill: {
        id: 'printCumsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentData: {},
      balanceBillData: [],
      paymentDetailData: [],
      paymentDetailVisible: false,
      paymentColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '付款单编号',
          dataIndex: 'serialNum'
        },
        {
          title: '报价编号',
          dataIndex: 'orderId',
          customRender: text => {
            return text.join('\n')
          }
        },
        {
          title: '接单日期',
          dataIndex: 'receivingDate',
          customRender: text => {
            return text.join('\n')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '付款对象',
          dataIndex: 'customer'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      balanceDetailVisible: false,
      balanceColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '结算单编号',
          dataIndex: 'serialNum'
        },
        {
          title: '订单编号',
          dataIndex: 'orderId',
          customRender: text => {
            return text.join('\n')
          }
        },
        {
          title: '接单日期',
          dataIndex: 'receivingDate',
          customRender: text => {
            return text.join('\n')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '客户',
          dataIndex: 'customer'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      balanceDetailData: [],
      printPayBillResult: [],
      printPayBillRecord: {},
      selectedVisible: false,
      selectedSummary: '',
      customerInfo: undefined,
      customerInfos: [],
      fetching: false,
      queryParam: {
        type: 3
      },
      confirmLoading: false,
      customerOps: [],
      taxRateOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          fixed: 'left',
          align: 'center'
        },
        {
          title: '订单编号',
          fixed: 'left',
          dataIndex: 'serial_num'
        },
        {
          title: '委托客户',
          fixed: 'left',
          dataIndex: 'agency_name'
        },
        {
          title: '接单日期',
          dataIndex: 'record_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY-MM-DD');
          }
        },
        {
          title: '应收费用',
          dataIndex: 'charge_fees',
          scopedSlots: { customRender: 'chargeFeeItem' }
        },
        {
          title: '应付费用',
          dataIndex: 'pay_fees',
          scopedSlots: { customRender: 'payFeeItem' }
        },
        {
          title: '品名',
          dataIndex: 'product_name'
        },
        {
          title: '客户编号',
          dataIndex: 'customer_num'
        },
        {
          title: '客服员',
          dataIndex: 'customer'
        },
        {
          title: '港区',
          dataIndex: 'port'
        },
        {
          title: '贸易方式',
          dataIndex: 'trade_type_name'
        },
        {
          title: '毛重',
          dataIndex: 'gloss_weight'
        },
        {
          title: '净重',
          dataIndex: 'weight'
        },
        {
          title: '件数',
          dataIndex: 'num'
        },
        {
          title: '总体积',
          dataIndex: 'total_volume'
        },
        {
          title: '提运单号',
          dataIndex: 'delivery_num'
        },
        {
          title: '船名航次',
          dataIndex: 'ship_name'
        },
        {
          title: '箱量',
          dataIndex: 'box_message'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getCumsFinancePage('verify_list', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      formVisible: false,
      applyFormVisible: false,
      // 商务审核申请form
      form: {
        pay_method: '',
        appendix_num: null,
        apply_remark: '',
        invoice_num: [],
        contract_list: []
      },
      invoiceTypeList: [
        {
          value: 1, name: '专用发票'
        },
        {
          value: 2, name: '普通发票'
        },
        {
          value: 3, name: '免税发票'
        }
      ],
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      invoiceNumForm: {
        invoiceNum: null
      },
      bizAuditForm: {
        audit_record_id: '',
        customer: '',
        department: '客服部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      invoiceNumFormRules: {
        invoiceNum: [
          { required: true, message: '请输入发票号', trigger: 'blur' }
        ]
      },
      rules: {
        customer_name: [
          { required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }
        ],
        tax_no: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
        ],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }]
      },
      indeterminate: false,
      checkAll: true,
      totalAmount: '',
      payTotalAmount: '',
      checkedList: [],
      applyInvoiceNumFormVisible: false,
      confirmInvoiceNumLoading: false,
      bankAccountOps: [],
      auditVisible: false,
      auditConfirmLoading: false,
      auditType: 1,
      auditData: [],
      checkedAuditList: [],
      auditIndeterminate: false,
      auditCheckAll: true,
      balanceVisible: false,
      balanceConfirmLoading: false,
      chargeMoney: 0,
      payMoney: 0,
      checkedBalanceList: [],
      balanceData: [],
      paymentVisible: false,
      paymentConfirmLoading: false,
      checkedPayTarget: null,
      paymentPayTarget: [],
      payIndeterminate: false,
      payCheckAll: true,
      checkedPaymentList: [],
      paymentData: [],
      supplierOps: [],
      businessAuditFormVisible: false,
      businessAuditConfirmLoading: false,
      invoiceRegOps: [],
      customerInfo2: undefined,
      fetching2: false,
      customerInfoOps2: [],
      leaseInfoList: [],
      totalInvoiceAmount: 0.00,
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      secondInvoiceSubjectList: [],
      invoiceSubjectData: [],
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        }
      ]
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
      this.customerInfos = v;
    });
    getSupplier({ department: 7 }).then(res => {
      this.supplierOps = res
    })
    listSecondInvoiceSubject({ department: 7 }).then(v => {
      this.secondInvoiceSubjectList = v
    })
    // getCustomerInfo({ department: 7 }).then(res => {
    //   this.customerInfoOps2 = res
    // })
  },
  methods: {
    moment,
    filterOption,
    changeInvoiceType(value) {
      if (value === 3) {
        for (const item of this.taxRateOps) {
          if (item.name === '0%销项税') {
            this.applyInvoiceForm.tax_rate = item.value
          }
        }
      }
    },
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.customerInfoOps2 = [];
      this.fetching2 = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.customerInfoOps2 = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value;
      this.applyInvoiceForm.customer_name = value !== undefined ? value.label : null;
      this.customerInfoOps2.forEach(item => {
        if (item.value === value.key) {
          this.applyInvoiceForm.customer_id = value.key
          this.applyInvoiceForm.tax_no = item.taxNum;
          this.applyInvoiceForm.address = item.street;
          this.applyInvoiceForm.mobile = item.phone;
          this.applyInvoiceForm.bank_card_no = item.account;
          this.applyInvoiceForm.bank = item.bankName;
        }
      });
      this.customerInfoOps2 = [];
      this.fetching2 = false;
    },
    applyInvoiceCancel() {
      this.applyInvoiceVisible = false;
      this.totalAmount = '';
      this.payTotalAmount = '';
      this.invoiceSubjectData = []
      this.applyInvoiceForm.invoice_subject_data = []
    },
    billOrVerifySubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          CumsFinanceFunc('business_audit', {
            pay_method: this.form.pay_method,
            invoice_num: this.form.invoice_num,
            apply_remark: this.form.apply_remark,
            appendix_num: this.form.appendix_num,
            id_list: this.checkedPaymentList,
            contract_list: this.form.contract_list
          }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.form.pay_method = ''
            this.form.appendix_num = null
            this.form.apply_remark = ''
            this.form.invoice_num = []
            this.selectedRowKeys = []
            this.clearSelectItem()
            this.businessAuditFormVisible = false
            this.$refs.table.refresh(false)
          });
        }
      })
    },
    billOrverifyCancel() {
      this.businessAuditFormVisible = false;
      this.totalAmount = ''; // 每次关闭弹出框后将会将总金额进行重置
    },
    // 提交商务审核
    handleApplyBizAudit() {
      this.checkedList = []
      this.totalAmount = ''
      const feeMaps = []
      let flag = false
      const map = new Map()
      this.selectedRows.forEach(v => {
        const orderFee = {
          orderNums: v.serial_num,
          feeList: v.pay_fees && v.pay_fees
            .filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
              if (map.has(e.currency_name)) {
                map.set(e.currency_name, e.money + map.get(e.currency_name))
              } else {
                map.set(e.currency_name, e.money)
              }
              if (e.fee_status === 2 || e.fee_status === 4) {
                flag = true
              }
              this.checkedList.push(e.id)
              return {
                id: e.id,
                feeName: e.fee_name,
                money: e.money,
                currency_name: e.currency_name,
                pay_target_name: e.pay_target_name
              }
            })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.createPayment()
        this.payment_id = this.selectedRows[0].id
        this.feeOrderList = feeMaps
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        getCommonNoPage('invoice_registration', {}).then((v) => {
          this.invoiceRegOps = v
        })
        const idList = []
        this.selectedRows.forEach(item => {
          item.pay_fees.forEach(fee => {
            idList.push(fee.pay_target_id)
          })
        })
        if (idList) {
          getLeaseInfoListByDepartment({ 'department': 7, 'customer_id_list': idList }).then(v => {
            this.leaseInfoList = v
          })
        }
        this.businessAuditFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    onChangeBalance(checkedValues) {
      let chargeMoney = 0
      let payMoney = 0
      const charge = this.balanceData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (checkedValues.indexOf(e.id) >= 0) {
              chargeMoney += e.money
            }
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      const pay = this.balanceData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (checkedValues.indexOf(e.id) >= 0) {
              payMoney += e.money
            }
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.chargeMoney = chargeMoney.toFixed(2)
      this.payMoney = payMoney.toFixed(2)
      this.indeterminate =
        !!this.checkedBalanceList.length && this.checkedBalanceList.length < chargeIdList.length + payIdList.length
      this.checkAll = this.checkedBalanceList.length === chargeIdList.length + payIdList.length
    },
    onCheckAllChangeBalance(e) {
      this.checkAll = e.target.checked
      let chargeMoney = 0
      let payMoney = 0
      this.indeterminate = false
      this.checkedBalanceList = []
      const charge = this.balanceData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (this.checkAll) {
              chargeMoney += e.money
            }
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      const pay = this.balanceData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            if (this.checkAll) {
              payMoney += e.money
            }
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.chargeMoney = chargeMoney.toFixed(2)
      this.payMoney = payMoney.toFixed(2)
      if (e.target.checked) {
        this.checkedBalanceList = this.checkedBalanceList.concat(chargeIdList, payIdList)
      }
    },
    // 结算单提交
    balanceSubmit() {
      this.balanceConfirmLoading = true
      getCumsBalanceFunc('create_cums_balance', {
        fee_ids: this.checkedBalanceList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '创建结算单成功'
            })
            this.balanceVisible = false
            this.$refs.table.refresh(false)
          }
        })
        .finally(_ => {
          this.balanceConfirmLoading = false
        })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['record_start_time'] = startDate
        this.queryParam['record_end_time'] = endDate
      } else {
        this.queryParam['record_start_time'] = null
        this.queryParam['record_end_time'] = null
      }
    },
    onInvoiceDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_start_date'] = startDate
        this.queryParam['invoice_end_date'] = endDate
      } else {
        this.queryParam['invoice_start_date'] = null
        this.queryParam['invoice_end_date'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.queryParam['auth_agency'] = null;
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.queryParam['auth_agency'] = value.key;
      }
      this.customerOps = []
      this.fetching = false
    },
    // 审核反审核提交
    submitAudit() {
      this.auditConfirmLoading = true
      const applyName = this.auditType === 1 ? 'basic_audit' : 'undo_audit'
      CumsFinanceFunc(applyName, {
        ids: this.checkedAuditList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            const feeStatus = applyName === 'basic_audit' ? 2 : 1
            this.selectedRows.forEach(item => {
              if (item.charge_fees) {
                item.charge_fees.forEach(fee => {
                  if (this.checkedAuditList.indexOf(fee.id) >= 0) {
                    fee.fee_status = feeStatus
                  }
                })
              }
              if (item.pay_fees) {
                item.pay_fees.forEach(fee => {
                  if (this.checkedAuditList.indexOf(fee.id) >= 0) {
                    fee.fee_status = feeStatus
                  }
                })
              }
            })
          }
        })
        .finally(res => {
          this.auditVisible = false
          this.auditConfirmLoading = false
        })
    },
    createBalance() {
      const customerNameSet = new Set()
      for (const item of this.selectedRows) {
        customerNameSet.add(item.agency_name)
      }
      if (customerNameSet.size > 1) {
        this.$notification['warning']({
          message: '提示',
          description: '委托客户不同，不能生成在一张结算单中'
        })
        return
      }
      const data = this.selectedRows.map(item => {
        return {
          id: item.order_id,
          serial_num: item.serial_num,
          charge: item.charge_fees
            ? item.charge_fees.filter(item => item.fee_status === 2 && item.is_balance === 1)
            : [],
          pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status >= 2 && item.is_balance === 1) : []
        }
      })
      this.balanceData = data
      let chargeMoney = 0
      let payMoney = 0
      const charge = data
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            chargeMoney += e.money
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      const pay = data
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
            payMoney += e.money
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.checkedBalanceList = chargeIdList.concat(payIdList)
      this.chargeMoney = chargeMoney.toFixed(2)
      this.payMoney = payMoney.toFixed(2)
      if (this.checkedBalanceList.length === 0) {
        this.$notification['warn']({
          message: '提示',
          description: '没有已审核未加入结算单的费用'
        })
      } else {
        this.balanceVisible = true
      }
    },
    // 创建付款单
    createPayment() {
      const payData = []
      let payMoney = 0
      for (const item of this.selectedRows) {
        if (item.pay_fees) {
          for (const fee of item.pay_fees) {
            if (fee.fee_status === 2 && fee.is_payment === 1) {
              fee.serial_num = item.serial_num
              payData.push(fee)
            }
          }
        }
      }
      this.payData = payData
      const data = []
      for (const item of payData) {
        let exist = false
        for (const payTarget of data) {
          if (payTarget.id === item.pay_target_id) {
            exist = true
          }
        }
        if (!exist) {
          data.push({
            id: item.pay_target_id,
            name: item.pay_target_name
          })
        }
      }
      this.paymentPayTarget = data
      let paymentData = []
      if (data.length > 0) {
        this.checkedPayTarget = data[0].id
        paymentData = payData.filter(fee => fee.pay_target_id === data[0].id)
        paymentData.forEach(item => {
          payMoney += item.money
        })
      }
      this.checkedPaymentList = paymentData.map(item => item.id)
      this.paymentData = paymentData
      this.payMoney = payMoney.toFixed(2)
      // if (paymentData.length > 0) {
      //   this.paymentVisible = true
      // } else {
      //   this.$notification['warn']({
      //     message: '提示',
      //     description: '没有已审核未加入付款单的费用'
      //   })
      // }
    },
    paymentSubmit() {
      this.paymentConfirmLoading = true
      getCumsPayBillFunc('create_cums_payment', {
        fee_ids: this.checkedPaymentList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '创建付款单成功'
            })
            this.paymentVisible = false
            this.$refs.table.refresh(false)
          }
        })
        .finally(_ => {
          this.paymentConfirmLoading = false
        })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelect (record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record);
      }

      if (!selected) {
        const delIndex = this.selectedRows.findIndex(val => {
          return val.serial_num === record.serial_num
        })
        this.selectedRows.splice(delIndex, 1);
      }
    },
    auditOpen(type) {
      if (type === 'basic_audit') {
        this.auditType = 1
        const data = this.selectedRows.map(item => {
          return {
            id: item.order_id,
            serial_num: item.serial_num,
            charge: item.charge_fees ? item.charge_fees.filter(item => item.fee_status === 1) : [],
            pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status === 1) : []
          }
        })
        this.auditData = data
        // 要提交审核的应收费用
        const charge = data
          .map(item => item.charge)
          .map(item => {
            const idList = []
            for (const e of item) {
              idList.push(e.id)
            }
            return idList
          })
        let idList = []
        for (const item of charge) {
          idList = idList.concat(item)
        }
        // 审核的应付费用
        const pay = data
          .map(item => item.pay)
          .map(item => {
            const idList = []
            for (const e of item) {
              idList.push(e.id)
            }
            return idList
          })
        for (const item of pay) {
          idList = idList.concat(item)
        }
        this.checkedAuditList = idList
        if (this.checkedAuditList.length === 0) {
          this.$notification['warn']({
            message: '提示',
            description: '没有可以审核的费用'
          })
        } else {
          this.auditVisible = true
        }
      } else {
        this.auditType = 2
        const data = this.selectedRows.map(item => {
          return {
            id: item.order_id,
            serial_num: item.serial_num,
            charge: item.charge_fees
              ? item.charge_fees.filter(item => item.fee_status === 2 && item.is_balance === 1)
              : [],
            pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status === 2 && item.is_payment === 1) : []
          }
        })
        this.auditData = data
        // 结算单反审核
        const charge = data
          .map(item => item.charge)
          .map(item => {
            const idList = []
            for (const e of item) {
              idList.push(e.id)
            }
            return idList
          })
        let idList = []
        for (const item of charge) {
          idList = idList.concat(item)
        }
        // 付款单反审核
        const pay = data
          .map(item => item.pay)
          .map(item => {
            const idList = []
            for (const e of item) {
              idList.push(e.id)
            }
            return idList
          })
        for (const item of pay) {
          idList = idList.concat(item)
        }
        this.checkedAuditList = idList
        if (this.checkedAuditList.length === 0) {
          this.$notification['warn']({
            message: '提示',
            description: '没有可以反审核的费用'
          })
        } else {
          this.auditVisible = true
        }
      }
    },
    handleAudit(operate) { // 业务审核与反审核
      const data = [];
      this.selectedRows.forEach(v => {
        v.fees.forEach(fee => {
          switch (operate) {
            // 审核
            case 'basic_audit':
              if (fee.fee_status === 1) data.push(v.id);
              break;
            // 反审核
            case 'undo_audit':
              if (fee.fee_status === 2) data.push(v.id);
              break;
          }
        });
      });
      if (data.length > 0) {
        CumsFinanceFunc(operate, {
          type: 1,
          ids: data
        }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          });
          this.selectedRows = [];
          this.selectedRowKeys = [];
          this.$refs.table.refresh(false);
        });
      } else {
        this.$message.warning(operate === 'basic_audit' ? '请选择已对账待审核的数据' : '请选择业务已审核的数据');
      }
    },
    // 开票申请
    handleBillApply() {
      this.checkedList = []
      this.totalAmount = ''
      this.payTotalAmount = ''
      let flag = false
      const feeMaps = []
      const map = new Map()
      const payMap = new Map()
      this.selectedRows.forEach(row => {
        const orderFee = {
          orderNums: row.serial_num,
          feeList: row.charge_fees && row.charge_fees.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
            if (map.has(e.currency_name)) {
              map.set(e.currency_name, e.money + map.get(e.currency_name))
            } else {
              map.set(e.currency_name, e.money)
            }
            if (e.fee_status === 2 || e.fee_status === 4) {
              flag = true
            }
            this.checkedList.push(e.id)
            return {
              id: e.id,
              feeName: e.fee_name,
              money: e.money,
              currency_name: e.currency_name
            }
          }),
          payFeeList: row.pay_fees && row.pay_fees.filter(e => e.is_balance === 1).map(e => {
            if (payMap.has(e.currency_name)) {
              payMap.set(e.currency_name, e.money + payMap.get(e.currency_name))
            } else {
              payMap.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
            return {
              id: e.id,
              feeName: e.fee_name,
              money: e.money,
              currency_name: e.currency_name,
              pay_target_name: e.pay_target_name
            }
          })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.bankAccountOps = []
        this.getCustomerOps(this.selectedRows[0].agency_name, this.selectedRows[0].customer_id)
        this.bizAuditForm = {
          ...this.bizAuditForm,
          audit_record_id: this.selectedRows[0].audit_record_id,
          customer: this.selectedRows[0].agency_name,
          fees: feeMaps
        }
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        for (const key of payMap.keys()) {
          this.payTotalAmount += payMap.get(key).toFixed(2) + key + ','
        }
        this.applyInvoiceVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    getCustomerOps(name, id) {
      getCustomerInfo({
        department: null,
        customerName: name,
        customerId: id
      }).then(res => {
        res.forEach(data => {
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.applyInvoiceForm.customer_id = typeof data.value !== 'undefined' ? data.value : ''
          this.applyInvoiceForm.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.applyInvoiceForm.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.applyInvoiceForm.address = typeof data.street !== 'undefined' ? data.street : ''
          this.applyInvoiceForm.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.applyInvoiceForm.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.applyInvoiceForm.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.form.bank = item.bank_name
        }
      })
    },
    // 提交开票
    handleApplyFormSubmit() {
      this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          this.applyInvoiceForm.invoice_subject_data = this.invoiceSubjectData
          const data = {
            ...this.applyInvoiceForm,
            id_list: this.checkedList
          };
          // bizAuditForm.fees

          CumsFinanceFunc('apply_ticket', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
            this.totalAmount = ''
            this.payTotalAmount = ''
            this.applyInvoiceVisible = false
            this.selectedRowKeys = []
            this.clearSelectItem()
            this.$refs.table.refresh(false)
          }).finally(() => {
            
          });
        }
      })
    },
    handleExport(type) {
      if (this.selectedRows.length > 0) {
        // 将所选内容进行导出
        this.queryParam['ids'] = this.selectedRows.map(item => item.id)
      }
      getCumsFinancePage(type, this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    onChangeAudit(checkedValues) {
      // 应收
      const charge = this.auditData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      // 应付
      const pay = this.auditData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.auditIndeterminate =
        !!this.checkedAuditList.length && this.checkedAuditList.length < chargeIdList.length + payIdList.length
      this.auditCheckAll = this.checkedAuditList.length === chargeIdList.length + payIdList.length
    },
    onCheckAllChangeAudit(e) {
      this.auditCheckAll = e.target.checked
      this.auditIndeterminate = false
      this.checkedAuditList = []
      const charge = this.auditData
        .map(item => item.charge)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let chargeIdList = []
      for (const item of charge) {
        chargeIdList = chargeIdList.concat(item)
      }
      if (e.target.checked) {
        this.checkedAuditList = this.checkedAuditList.concat(chargeIdList)
      }
    },
    // 结算单
    feeChargeStatusName(value) {
      if (value.fee_status === 1) {
        return '待审核'
      } else {
        if (value.fee_status === 2 && value.is_balance === 1) {
          return '已审核'
        } else {
          return '添加到结算单'
        }
      }
    },
    // 付款单
    feePayStatusName(value) {
      if (value.fee_status === 1) {
        return '待审核'
      } else {
        if (value.fee_status === 2 && value.is_payment === 1) {
          return '已审核'
        } else {
          return '添加到付款单'
        }
      }
    },
    // 费用颜色
    chargeFeeStatusColor(value) {
      if (value.fee_status === 1) {
        return '#faad14'
      } else {
        if (value.fee_status === 2 && value.is_balance === 1) {
          return '#1890ff'
        } else {
          return '#4cd964'
        }
      }
    },
    payFeeStatusColor(value) {
      if (value.fee_status === 1) {
        return '#faad14'
      } else {
        if (value.fee_status === 2 && value.is_payment === 1) {
          return '#1890ff'
        } else {
          return '#4cd964'
        }
      }
    },
    onCheckAllChangePayment(e) {
      this.payCheckAll = e.target.checked
      this.payIndeterminate = false
      this.checkedPaymentList = []
      let payMoney = 0
      this.paymentData.forEach(item => {
        if (this.payCheckAll) {
          payMoney += item.money
        }
      })
      this.payMoney = payMoney
      if (e.target.checked) {
        this.checkedPaymentList = this.paymentData.map(item => item.id)
      }
    },
    onChangePayment(checkedValues) {
      let payMoney = 0
      this.paymentData.forEach(item => {
        if (checkedValues.indexOf(item.id) >= 0) {
          payMoney += item.money
        }
      })
      this.payMoney = payMoney
      this.payIndeterminate =
        !!this.checkedPaymentList.length && this.checkedPaymentList.length < this.paymentData.length
      this.payCheckAll = this.checkedPaymentList.length === this.paymentData.length
    },
    changePayTarget(e) {
      this.paymentData = this.payData.filter(fee => fee.pay_target_id === this.checkedPayTarget)
      let payMoney = 0
      this.paymentData.forEach(item => {
        payMoney += item.money
      })
      this.payMoney = payMoney
      this.checkedPaymentList = this.paymentData.map(item => item.id)
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.serial_num === item.serial_num) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          for (const item of changeRows) {
            if (item.serial_num === this.selectedRows[index].serial_num) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      this.payTotalAmount = ''
      const mapItem = new Map()
      const payMapItem = new Map()
      for (const item of this.bizAuditForm.fees) {
        count += item.feeList.length
        count += item.payFeeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
          for (let j = 0; j < item.payFeeList.length; j++) {
            if (checkedValues[i] === item.payFeeList[j].id) {
              if (payMapItem.has(item.payFeeList[j].currency_name)) {
                payMapItem.set(item.payFeeList[j].currency_name, item.payFeeList[j].money + payMapItem.get(item.payFeeList[j].currency_name))
              } else {
                payMapItem.set(item.payFeeList[j].currency_name, item.payFeeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      for (const key of payMapItem.keys()) {
        this.payTotalAmount += payMapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      this.payTotalAmount = ''
      const mapList = new Map()
      const payMapList = new Map()
      if (e.target.checked) {
        this.bizAuditForm.fees.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
          for (const e of item.payFeeList) {
            if (payMapList.has(e.currency_name)) {
              payMapList.set(e.currency_name, e.money + payMapList.get(e.currency_name))
            } else {
              payMapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
        for (const key of payMapList.keys()) {
          this.payTotalAmount += payMapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    removeSelectItem(serialNum) {
      for (let index = 0; index < this.selectedRowKeys.length; index++) {
        if (this.selectedRowKeys[index] === serialNum) {
          this.selectedRowKeys.splice(index, 1)
        }
      }
      for (let index = 0; index < this.selectedRows.length; index++) {
        if (this.selectedRows[index].serial_num === serialNum) {
          this.selectedRows.splice(index, 1)
        }
      }
    },
    clearSelectItem() {
      this.$refs.table.clearSelected()
      this.selectedRows = []
    },
    balanceDetail(record) {
      getBalanceDetailByOrderId({
        orderId: record.id,
        type: record.type
      }).then(res => {
        this.balanceDetailData = res
        this.balanceDetailVisible = true
      })
    },
    // 查看付款单
    paymentDetail(record) {
      getPaymentDetailByOrderId({
        orderId: record.id,
        type: record.type
      }).then(res => {
        this.paymentDetailData = res
        this.paymentDetailVisible = true
      })
    },
    getContainer() {
      return document.getElementById('selectArea')
    },
    // 结算单
    printDebitSheet(record) {
      if (typeof record.auditId !== 'undefined') {
        getBizAuditPage('list_balance', { 'id': record.auditId }).then(res => {
          // 统计数据
          let charge = 0
          let pay = 0
          let profit = 0
          res.balance_item_list.forEach(v => {
            v.charge_fees &&
            v.charge_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                charge += c.amount
              } else {
                charge += c.exchange_amount
              }
            })
            v.pay_fees &&
            v.pay_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                pay += c.amount
              } else {
                pay += c.exchange_amount
              }
            })
            profit += v.profit
          })
          this.balanceStatic = {
            charge: '应收总计:' + charge.toFixed(2),
            pay: '应付总计:' + pay.toFixed(2),
            profit: '利润总计:' + profit.toFixed(2)
          }
          this.balanceBillData = res.balance_item_list
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceStatic = this.balanceStatic
          this.currentData.currency_name = res.currency
          this.currentData.customer_name = record.customer
          this.currentData.record_no = record.serialNum
          this.currentData.creator_name = record.createName
          this.currentData.apply_remark = record.remark
          this.currentData.currency = res.currency
        }).finally(() => {
          this.printCumsBillModel = true
        })
      } else {
        this.$message.error('当前订单尚未提交开票申请~')
      }
    },
    // 付款对账单
    handlePayBills(record) {
      this.currentData = record
      getFmsInfoDetail('business_audit', { id: record.auditId }).then(v => {
        const data = v
        const result = []
        data.forEach(item => {
          const feeList = item.pay_fee_list;
          if (typeof feeList !== 'undefined') {
            feeList.forEach(fee => {
              result.push(fee)
            })
          }
        })
        this.printPayBillResult = result
        this.printPayBillRecord = this.currentData
        this.printPayBillModel = true
      });
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    }
  }
}
</script>

<style>
.row-footer .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: none;
}
.selectContextOpen {
  margin: 0;
}
.selectContextClose {
  margin: 0;
  height: 320px;
  overflow: auto;
}
.fee-area {
  display: flex;
  flex-direction: row;
}
.fee-list {
  flex: 1;
}
.select-summary {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
.select-list-item {
  margin-top: 10px;
  padding: 5px 10px;
  border: 1px solid rgb(231, 231, 231);
}
.sub-fee-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
}
</style>
